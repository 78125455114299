import React from 'react';
import { MapWithPanel } from './MapWithPanel';

function App() {
  return (
    <div className="App">
      <MapWithPanel />
    </div>
  );
}

export default App;
