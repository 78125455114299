import React, { useState, useEffect, useRef } from 'react';
import { MapContainer, TileLayer, useMap, LayersControl } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import './MapWithPanel.css'; 


export const MapWithPanel = () => {
	const [isPanelOpen, setIsPanelOpen] = useState(false);
	const [panelContent, setPanelContent] = useState(null);
	const [selectedLayer, setSelectedLayer] = useState("none");


  const togglePanel = (contentType) => {
	setPanelContent(contentType);
    	setIsPanelOpen(!isPanelOpen);
  };

//const weatherLayerUrl = "https://gibs.earthdata.nasa.gov/wmts/epsg3857/best/"
const windLayerUrl = "https://tile.openweathermap.org/map/wind_new/{z}/{x}/{y}.png?appid=886d68fe73eef622c486b44927b1cf8e";
const tempLayerUrl = "https://tile.openweathermap.org/map/temp_new/{z}/{x}/{y}.png?appid=886d68fe73eef622c486b44927b1cf8e";
const precpLayerUrl = "https://tile.openweathermap.org/map/precipitation_new/{z}/{x}/{y}.png?appid=886d68fe73eef622c486b44927b1cf8e";
const cloudLayerUrl = "https://tile.openweathermap.org/map/clouds_new/{z}/{x}/{y}.png?appid=886d68fe73eef622c486b44927b1cf8e";
const pressLayerUrl = "https://tile.openweathermap.org/map/pressure_new/{z}/{x}/{y}.png?appid=886d68fe73eef622c486b44927b1cf8e";
const baseLayerUrl = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
 const handleLayerChange = (event) => {
    setSelectedLayer(event.target.value);
  };

 const renderPanelContent = () => {
    switch (panelContent) {
      case 'login':
        return (
          <div className="panel-content">
            <h3>Login</h3>
            <input type="text" placeholder="Username" />
            <input type="password" placeholder="Password" />
            <button>Submit</button>
          </div>
        );
	case 'menu':
        return (
          <div className="panel-content">
            <h3>Login</h3>
            <input type="text" placeholder="Username" />
            <input type="password" placeholder="Password" />
            <button>Submit</button>
            <h3>Goto</h3>
            <input type="text" placeholder="Lattitude" />
            <input type="text" placeholder="Longitude" />
            <button>Go</button>
            <h3>Layers</h3>
		<label>
              <input
                type="radio"
                name="layer"
                value="none"
                onChange={handleLayerChange}
                checked={selectedLayer === "none"}
              />
              None
            </label>
		<label>
              <input type="radio" name="layer" value="ActiveFires" />
              Active Fires
            </label><br/>
            <label>
              <input type="radio" name="layer" value="PredictedRiskQuotient " />
              Predicted Risk Quotient
            </label><br/>
            <label>
              <input type="radio" name="layer" value="Weather" onChange={handleLayerChange} checked={selectedLayer === "Weather"}/>
              Weather
            </label><br/>
            <label>
              <input type="radio" name="layer" value="Wind"onChange={handleLayerChange} checked={selectedLayer === "Wind"} />
              Wind
            </label><br/>
            <label>
              <input type="radio" name="layer" value="Moisture" onChange={handleLayerChange} checked={selectedLayer === "Moisture"}/>
              Moisture
            </label><br/>
            <label>
<input type="radio" name="layer" value="PopulationDensity" />
              Population Density
            </label><br/>
            <label>
              <input type="radio" name="layer" value="Recommendations" />
              Recommendations
            </label><br/>
	</div>
        );
	case 'gps':
        return (
          <div className="panel-content">
            <h3>Goto</h3>
            <input type="text" placeholder="Lattitude" />
            <input type="text" placeholder="Longitude" />
            <button>Go</button>
          </div>
        );
      case 'layers':
        return (
          <div className="panel-content">
            <h3>Layers</h3>
            <label>
              <input type="radio" name="layer" value="ActiveFires" />
              Active Fires
            </label><br/>
            <label>
              <input type="radio" name="layer" value="PredictedRiskQuotient " />
              Predicted Risk Quotient
            </label><br/>
            <label>
              <input type="radio" name="layer" value="Weather" onChange={handleLayerChange} checked={selectedLayer === "Weather"}/>
              Weather
            </label><br/>
            <label>
              <input type="radio" name="layer" value="Wind" />
              Wind
            </label><br/>
            <label>
              <input type="radio" name="layer" value="Moisture" />
              Moisture
            </label><br/>
            <label>
              <input type="radio" name="layer" value="PopulationDensity" />
              Population Density
            </label><br/>
            <label>
              <input type="radio" name="layer" value="Recommendations" />
              Recommendations
            </label><br/>
          </div>
        );
      default:
        return <div>Select an option</div>;
    }
  };

  return (
<div>
	  <div className="navBar">
	  <button onClick={() => togglePanel('menu')} class="menu">
<svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 -2 24 24">
  <path stroke="currentColor" stroke-linecap="round" stroke-width="2" d="M5 7h14M5 12h14M5 17h14"/>
</svg>

	  </button>
        <h3>&nbsp;TriNater</h3>
	  <button class="loginButton" onClick={() => togglePanel('login')}>Login</button>
      </div>
    <div className="app-container">


        <button onClick={() => togglePanel('login')} className="pullTab">
	  <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 12H8m12 0-4 4m4-4-4-4M9 4H7a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h2"/>
</svg>
        </button>



	  <div className={`side-panel ${isPanelOpen ? 'open' : 'closed'}`}>
	  <div id="panel-content">
<button onClick={() => setIsPanelOpen(false)} className="loginButton">
          X
        </button>
        {isPanelOpen && renderPanelContent()}


	</div>
	</div>



	  <div className="map-container">

	  <div className="floating-buttons">
          <button onClick={() => togglePanel('layers')} className="layers-button">
	<svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
  <path fill-rule="evenodd" d="M5.005 10.19a1 1 0 0 1 1 1v.233l5.998 3.464L18 11.423v-.232a1 1 0 1 1 2 0V12a1 1 0 0 1-.5.866l-6.997 4.042a1 1 0 0 1-1 0l-6.998-4.042a1 1 0 0 1-.5-.866v-.81a1 1 0 0 1 1-1ZM5 15.15a1 1 0 0 1 1 1v.232l5.997 3.464 5.998-3.464v-.232a1 1 0 1 1 2 0v.81a1 1 0 0 1-.5.865l-6.998 4.042a1 1 0 0 1-1 0L4.5 17.824a1 1 0 0 1-.5-.866v-.81a1 1 0 0 1 1-1Z" clip-rule="evenodd"/>
  <path d="M12.503 2.134a1 1 0 0 0-1 0L4.501 6.17A1 1 0 0 0 4.5 7.902l7.002 4.047a1 1 0 0 0 1 0l6.998-4.04a1 1 0 0 0 0-1.732l-6.997-4.042Z"/>
</svg>

	  </button>
          <button onClick={() => togglePanel('gps')} className="maps-button">
<svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 13a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"/>
  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.8 13.938h-.011a7 7 0 1 0-11.464.144h-.016l.14.171c.1.127.2.251.3.371L12 21l5.13-6.248c.194-.209.374-.429.54-.659l.13-.155Z"/>
</svg>

	  </button>
        </div>


	  <MapContainer center={[45.5152, -122.6784]} zoom={9} style={{ height: '100%', width: '100%' }}>

<LayersControl position="topright">
        <LayersControl.BaseLayer name="Base Map" checked>
          <TileLayer
            url={baseLayerUrl}
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />
        </LayersControl.BaseLayer>

        <LayersControl.Overlay name="Weather" checked={selectedLayer === "Weather"}>
           {/* Conditionally render weather tile layer and wind layer */}
          {selectedLayer === "Weather" && (
            <>
              <TileLayer
                 url={cloudLayerUrl}
                 attribution='&copy; <a href="https://openweathermap.org">OpenWeatherMap</a> contributors'
               />
            </>
            )}
        </LayersControl.Overlay>


        <LayersControl.Overlay name="Wind" checked={selectedLayer === "Wind"}>
           {/* Conditionally render weather tile layer and wind layer */}
          {selectedLayer === "Wind" && (
            <>
              <TileLayer
                 url={windLayerUrl}
                 attribution='&copy; <a href="https://openweathermap.org">OpenWeatherMap</a> contributors'
               />
            </>
            )}
        </LayersControl.Overlay>

        <LayersControl.Overlay name="Moisture" checked={selectedLayer === "Moisture"}>
           {/* Conditionally render weather tile layer and wind layer */}
          {selectedLayer === "Moisture" && (
            <>
              <TileLayer
                 url={precpLayerUrl}
                 attribution='&copy; <a href="https://openweathermap.org">OpenWeatherMap</a> contributors'
               />
            </>
            )}
        </LayersControl.Overlay>

      </LayersControl>




        </MapContainer>




      </div>

	  </div>
	  </div>
  );
};
